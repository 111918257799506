/* portfolio */
@media all {

	.portfolio {
			
	}

	.portfolio__box + .portfolio__box {
			margin-top: 3rem;
	}

	@media (min-width: 768px) {
			.portfolio__box + .portfolio__box {
					margin-top: 4rem;
			}
	}
	@media (min-width: 1000px) {
			.portfolio__box + .portfolio__box {
					margin-top: 6rem;
			}
	}

	.portfolio__list {
	}
	@media (min-width: 600px) {
			.portfolio__list {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-pack: justify;
					-ms-flex-pack: justify;
					justify-content: space-between;
					-ms-flex-wrap: wrap;
					flex-wrap: wrap;
					-webkit-box-align: stretch;
					-ms-flex-align: stretch;
					align-items: stretch;
			}
	}
	@media (min-width: 1000px) {
			.portfolio__list {
					-webkit-box-pack: start;
					-ms-flex-pack: start;
					justify-content: flex-start;
			}
	}

	.portfolio__item {
			position: relative;
			overflow: hidden;
			max-width: 36rem;
			width: 100%;
			background: #BABABA;
	}

	.portfolio__item + .portfolio__item {
			margin-top: 2rem;
	}
	@media (min-width: 600px) {
			.portfolio__item {
					max-width: 48%;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					-ms-flex-direction: column;
					flex-direction: column;
					-webkit-box-align: stretch;
					-ms-flex-align: stretch;
					align-items: stretch;
			}
			.portfolio__item + .portfolio__item {
					margin-top: 0;
			}
			.portfolio__item:nth-child(n + 3) {
					margin-top: 4%;
			}
	}
	@media (min-width: 1000px) {
			.portfolio__item {
					max-width: 32%;
			}
			.portfolio__item:nth-child(n + 3) {
					margin-top: 0;
			}
			.portfolio__item:nth-child(n + 4) {
					margin-top: 2%;
			}
			.portfolio__item:nth-child(3n - 1),
			.portfolio__item:nth-child(3n) {
					margin-left: 2%;
			}
	}

	.portfolio__preview {
			background: #BABABA;
			border: .2rem solid #BABABA;
			border-bottom-width: 0;
			overflow: hidden;
			position: relative;
	}
	.portfolio__preview:before,
	.portfolio__preview:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			border-radius: 50%;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			z-index: 0;
	}
	.portfolio__preview:before {
			width: 10rem;
			height: 10rem;
			margin: -5rem 0 0 -5rem;
			border-top: .2rem solid #2B2B2B;
			border-right: .2rem solid transparent;
			border-bottom: .2rem solid #2B2B2B;
			border-left: .2rem solid transparent;
			-webkit-animation: rotate linear infinite 2s 0s;
			animation: rotate linear infinite 2s 0s;
	}
	.portfolio__preview:after {
			width: 9rem;
			height: 9rem;
			margin: -4.5rem 0 0 -4.5rem;
			border-top: .2rem solid transparent;
			border-right: .2rem solid #2B2B2B;
			border-bottom: .2rem solid transparent;
			border-left: .2rem solid #2B2B2B;
			-webkit-animation: backRotate linear infinite 2s 0s;
			animation: backRotate linear infinite 2s 0s;
	}

	.portfolio__image {
			width: 100%;
			display: block;
			position: relative;
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center center;
			object-position: center center;
			z-index: 1;
			height: 17rem;
			transition: all ease .5s;
			&:hover {
				transform: scale(1.1);
			}
	}
	.portfolio__image._d {
			display: none;
	}
	@media (min-width: 768px) {
			.portfolio__image {
			}
			.portfolio__image._m {
					display: none;
			}
			.portfolio__image._d {
					display: block;
			}
	}

	.portfolio__info {
			position: relative;
			z-index: 1;
			right: 0;
			bottom: 0;
			left: 0;
			padding: 1rem;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;
			background: #BABABA;
			color: #2B2B2B;
	}
	@media (min-width: 600px) {
			.portfolio__info {
					-webkit-box-flex: 1;
					-ms-flex-positive: 1;
					flex-grow: 1;
			}
	}
	@media (min-width: 768px) {
			.portfolio__info {
					padding: 2rem;
					font-size: 0.8em;
			}
	}

	.portfolio__name {
			font-weight: bold;
	}

	.portfolio__links {
			margin-top: .6rem;
	}

	.portfolio__link {
			color: #2B2B2B;
			cursor: pointer;
			-webkit-transition: -webkit-text-decoration-color .2s;
			transition: -webkit-text-decoration-color .2s;
			-o-transition: text-decoration-color .2s;
			transition: text-decoration-color .2s;
			transition: text-decoration-color .2s, -webkit-text-decoration-color .2s;
	}
	.portfolio__link:hover {
			-webkit-text-decoration-color: transparent;
			text-decoration-color: transparent;
	}

	.portfolio__separator {
			opacity: .5;
	}
	.portfolio__tags {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			padding-top: 1rem;
			margin-top: auto;
			cursor: default;
	}
	.portfolio__tag {
			font-size: 1.2rem;
			line-height: 2rem;
			margin: 0 .4rem .4rem 0;
			border-radius: .2rem;
			background: #2b2b2b;
			padding: 0 .5rem;
			color: #BABABA;
	}
	.portfolio__tag._warning {
			background: #E44D26;
	}
	.portfolio__tag._archived {
			background: #7B1FA2;
	}

}

@-webkit-keyframes rotate {
	100% {-webkit-transform: rotate(360deg);transform: rotate(360deg)}
}

@keyframes rotate {
	100% {-webkit-transform: rotate(360deg);transform: rotate(360deg)}
}
@-webkit-keyframes backRotate {
	100% {-webkit-transform: rotate(-360deg);transform: rotate(-360deg)}
}
@keyframes backRotate {
	100% {-webkit-transform: rotate(-360deg);transform: rotate(-360deg)}
}