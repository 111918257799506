.popups {
	padding: 0;
	max-width: 1200px;
	width: 100%;

	img {
		width: 100%;
		height: 100%;
		display: flex;
	}
}