/* sections */
@media all {
	.loving-section {
			margin-bottom: 4rem;
			position: relative;
	}

	@media (min-width: 360px) {
			.loving-section {
					margin-bottom: 3rem;
			}
	}
	@media (min-width: 768px) {
			.loving-section {
					margin-bottom: 4rem;
			}
	}
	@media (min-width: 1000px) {
			.loving-section {
					margin-bottom: 6rem;
			}
	}
}

/* titles */
@media all {
	.loving-title {
			margin: 0;
			cursor: default;
			color: #ffffff;
	}

	.loving-title._h1 {
			font-size: 2rem;
			line-height: 2rem;
			font-weight: 900;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
	}

	@media (min-width: 360px) {
			.loving-title._h1 {
					font-size: 2rem;
			}
	}
	@media (min-width: 1000px) {
			.loving-title._h1 {
					font-size: 3rem;
			}
	}
	.loving-title._h2 {
			font-weight: 900;
			font-size: 2.4rem;
			margin-bottom: 2rem;
			line-height: 1em;
	}

	.loving-title._h2:before{
			content: '';
			position: absolute;
			top: 1.2rem;
			width: 50vw;
			left: 50%;
			-webkit-box-shadow: 50vw 0 0 0 #555;
			box-shadow: 50vw 0 0 0 #555;
			margin-left: -50vw;
			height: 1px;
			background: #555;
	}

	@media (min-width: 360px) {
			.loving-title._h2 {
					font-size: 3rem;
					margin-bottom: 3rem;
			}
			.loving-title._h2:before {
					top: 1.5rem;
			}
	}
	@media (min-width: 768px) {
			.loving-title._h2 {
					font-size: 4rem;
					margin-bottom: 5rem;
			}
			.loving-title._h2:before {
					height: 2px;
					top: 1.9rem;
			}
	}

	._h2 .loving-title__inside {
			position: relative;
			line-height: 1em;
			background: #2B2B2B;
			margin: 0 -1rem 0 -.3rem;
			padding: 0 1rem 0 .3rem;
	}


	@media (min-width: 360px) {
			._h2 .loving-title__inside {
					margin: 0 -.8rem 0 -.6rem;
					padding: 0 .8rem 0 .6rem;
			}
	}
	@media (min-width: 768px) {
			._h2 .loving-title__inside {
					margin: 0 -.8rem 0 -.8rem;
					padding: 0 .8rem 0 .8rem;
			}
	}
	@media (min-width: 1000px) {
			._h2 .loving-title__inside {
					margin: 0 -1rem 0 -1.1rem;
					padding: 0 1rem 0 1.1rem;
			}
	}
	@media (min-width: 1200px) {
			._h2 .loving-title__inside {
					margin: 0 -4rem 0 -4.2rem;
					padding: 0 4rem 0 4.2rem;
			}
	}

	.loving-title._h3 {
			font-weight: 900;
			font-size: 2rem;
			line-height: 3rem;
			margin-bottom: 1.6rem;
			color: #BABABA;
	}

	@media (min-width: 360px) {
			.loving-title._h3 {
					font-size: 2.4rem;
					margin-bottom: 2.4rem;
			}
	}
	@media (min-width: 768px) {
			.loving-title._h3 {
					font-size: 3rem;
					margin-bottom: 3rem;
			}
	}
}