/* content */
@media all {
	.content {
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		padding: 2rem 1rem;
		max-width: 106rem;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
		position: relative;
	}

	@media (min-width: 360px) {
		.content {
			padding: 3rem 2rem 0;
		}
	}

	@media (min-width: 768px) {
		.content {
			padding: 9rem 3rem 0;
		}
	}

	@media (min-width: 1000px) {
		.content {
			padding: 12rem 3rem 0;
		}
	}

	@media (min-width: 1200px) {

		.content:before,
		.content:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			width: 2px;
			z-index: 1;
			background: #555555;
		}

		.content:before {
			left: -1.3rem;
		}

		.content:after {
			right: -1.3rem;
		}
	}
}