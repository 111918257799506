@import 'common/_style';
@import 'popups/_style';
@import 'content/_style';
@import 'loving/_style';
@import 'header/_style';
@import 'nav/_style';
@import 'about/_style';
@import 'skills/_style';
@import 'portfolio/_style';
@import 'footer/_style';

@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600;700&display=swap');
